import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './Home.css';
import axios from 'axios';
import Busqueda from '../../img/Busqueda.png';
import { Link } from 'react-router-dom';
import basureroEliminar from '../../img/eliminar.png';

const getCompany = async (companyId) => {
  const jwtToken = localStorage.getItem('accessToken');
  try {
      const response = await axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/company/${companyId}`, {
          headers: {
              'Authorization': `Bearer ${jwtToken}`,
              'Content-Type': 'application/json'
          },
      })
      return response.data.empresa;
  } catch (error) {
      console.error("Hubo un error en la petición: ", error);
      return null;
  }
};

const FindCargo = (id_cargo, disponibilidades) => {
  const cargo = disponibilidades.find(d => d.id === id_cargo);
  return cargo ? cargo.disponibilidad : null;
};

const invalidateBusqueda = async (busquedaId) => {
  try {
    const jwtToken = localStorage.getItem('accessToken');
    await axios.patch(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/busqueda/invalidate/${busquedaId}`, {}, {
      headers: {
        'Authorization': `Bearer ${jwtToken}`,
        'Content-Type': 'application/json'
      }
    });
    window.location.reload();
  } catch (error) {
    console.error('Error al invalidar la búsqueda:', error);
  }
};

const BusquedaItem = ({ busqueda, disponibilidades }) => {
  const cargo = FindCargo(busqueda.busquedaId, disponibilidades);

  const formatearFecha = (fecha) => {
    const fechaObj = new Date(fecha);
    const dia = fechaObj.getDate();
    const mes = fechaObj.toLocaleString('es-ES', { month: 'long' });
    const ano = fechaObj.getFullYear();
    return `${dia} de ${mes} ${ano}`;
  };

  return (
    <div className="subcontainerMiPerfil">
      <div className="box-match">
        <h className="num-match">{busqueda.numDeMatch}</h>
        <h className="text-match">Match</h>
      </div>
      <div className="subcontainerCentral">
        <h className='nombre-busqueda'>{busqueda.busquedaNombre}</h>
        <h className='cargo'>En el cargo {busqueda.busquedaDisponibilidad}</h>
      </div>
      <div className="subcontainerDerecho">
        <div className="fecha-y-basurero">
          <h className='fecha'>{formatearFecha(busqueda.busquedaFecha)}</h>
          <button onClick={() => invalidateBusqueda(busqueda.busquedaId)} className="delete-button">
            <img src={basureroEliminar} alt="Eliminar" />
          </button>
        </div>
        <Link id="detalle" to={{ pathname: `/busqueda/${busqueda.busquedaId}` }}>Ver Detalle</Link>
      </div>
    </div>
  );
};

function Home() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [totalMatches, setTotalMatches] = useState('...');
    const [topMatches, setTopMatches] = useState([]);
    const [company, setCompany] = useState(null);
    const [busquedas, setBusquedas] = useState([]);
    const [disponibilidades, setDisponibilidades] = useState([]);
    const navigate = useNavigate();
    const jwtToken = localStorage.getItem('accessToken');
    const user = JSON.parse(localStorage.getItem('user'));
    const companyId = user.data.attributes.empresa.id;

    useEffect(() => {
      const jwtToken = Cookies.get('jwtToken');
      if (jwtToken) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        navigate("/");
      }
    }, [navigate]);

    useEffect(() => {
      axios.get('https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/obtenerListas/disponibilidades', {
        headers: {
          'Authorization': `Bearer ${jwtToken}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        setDisponibilidades(response.data);
      })
      .catch(error => console.error('Error al cargar disponibilidades:', error));

      getCompany(companyId).then((data) => {
        setCompany(data);
      });

      axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/busqueda/statisticMatches/${companyId}`, {
        headers: {
          'Authorization': `Bearer ${jwtToken}`,
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        setTotalMatches(response.data.totalMatches);
        setTopMatches(response.data.topMatches);
        setIsLoading(false);
      })
      .catch(error => {
        console.error("Error fetching statistics:", error);
        setIsLoading(false);
      });
    }, [companyId, jwtToken]);

    useEffect(() => {
        axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/busqueda/empresa/${companyId}`, {
            headers: {
              'Authorization': `Bearer ${jwtToken}`,
              'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if (Array.isArray(response.data)) {
                setBusquedas(response.data);
            } else {
                setBusquedas([]);
            }
        })
        .catch(error => console.error('Error:', error));
    }, [companyId, jwtToken]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredBusquedas = searchTerm.length > 0
        ? busquedas.filter(busqueda => busqueda.busquedaNombre.toLowerCase().includes(searchTerm.toLowerCase()))
        : busquedas;

    if (!company) return 'Cargando...';

    return (
        <>
            <div className='div_profile'></div>
            {isLoggedIn && (
                <div className="desktop_perfil">
                    <div className="div">
                        <div className="overlap-group">
                            <div className="rectangulo_principal">
                                <div className="rectangulo_seccion">
                                    <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap' rel='stylesheet'></link>
                                    <h3 className='message-home'>Busca un perfil para contratar</h3>
                                    <img src={Busqueda} alt="Busqueda" className="busqueda-image" />
                                    <div className="flex-container">
                                        {company.activo ? <a id="busqueda" href="/form-busqueda">Nueva búsqueda</a> : <a id="busqueda" className="disabled">Empresa Inactiva</a>}
                                    </div>
                                    <div style={{ height: '10px' }}></div>
                                    <div className="rectangle"></div>
                                    <div style={{ height: '3.5vw' }}></div>
                                    <div className="search-container">
                                        <input
                                            className="search-input"
                                            type="text"
                                            placeholder="Buscar búsqueda por nombre..."
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                    <div style={{ height: '10px' }}></div>
                                    <div className="rectangle2"></div>
                                    <h3 className='message-home2'>Búsquedas realizadas ({filteredBusquedas.length})</h3>
                                    {filteredBusquedas.map((busqueda, index) => <BusquedaItem key={index} busqueda={busqueda} disponibilidades={disponibilidades} />)}
                                </div>
                            </div>
                            <div className='rectangulo-arriba'>
                                <h3 className='message-home3'>Match total</h3>
                                <h3 className={`message-home4 ${isLoading ? 'loading-message' : ''}`}>
                                    {isLoading ? "Cargando..." : totalMatches}
                                </h3>
                                <h3 className='match-tot'>match conseguidos en total</h3>
                                <div style={{ height: '10px' }}></div>
                                <div className="rectangle3"></div>
                                <h3 className='message-home3'>Mejores 3 match</h3>
                                {topMatches.length > 0 ? (
                                    <ul className="top-matches-list">
                                        {topMatches.map((match, index) => (
                                            <li key={index} className="match-item">
                                                <div className="match-circle">
                                                    {Math.round(match.correspondencia * 100)}%
                                                </div>
                                                <div className="match-details">
                                                    <span className="match-name">{match.busquedaNombre}</span>
                                                    <span className="match-cargo">{match.busquedaDisponibilidad}</span>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className="no-matches">No hay matches disponibles.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Home;
