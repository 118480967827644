import './Header.css';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import {LuLogOut} from 'react-icons/lu';

import { ReactComponent as ProfileIcon } from './icons/profile_icon.svg';
import { ReactComponent as HomeIcon } from './icons/home_icon.svg';
import { ReactComponent as SearchIcon } from './icons/search_icon.svg';

import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal';
import LogoutModal from "./LogoutModal";

const getCompany = async (companyId) => {
  const jwtToken = localStorage.getItem('accessToken');
  try {
      const response = await axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/company/${companyId}`, {
          headers: {
              'Authorization': `Bearer ${jwtToken}`,
              'Content-Type': 'application/json'
          },
      })
      return response.data.empresa;
  } catch (error) {
      console.error("Hubo un error en la petición: ", error);
      return null;
  }
};

function Header() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const companyId = user.data.attributes.empresa.id; 
    const [company, setCompany] = useState(null);
    Modal.setAppElement('#root');

    useEffect(() => {
      const jwtToken = Cookies.get('jwtToken');
      if (jwtToken) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        navigate("/");
      }
    }, [navigate]);

    useEffect(() => {
      let isMounted = true; // variable para prevenir el cambio de estado después de desmontar
      getCompany(companyId).then((data) => {   
          if(isMounted) setCompany(data); 
      });
      return () => { isMounted = false };
    }, [companyId]);
  
    const handleLogout = () => {
      setLogoutModalOpen(false);
      Cookies.remove('jwtToken');
      navigate('/');
    }
  
    const handleOpenLogoutModal = () => {
      setLogoutModalOpen(true);
    };
  
    const handleCloseLogoutModal = () => {
      setLogoutModalOpen(false);
    };
  
    const location = useLocation();
  
    const isActive = (routePattern) => {
      return location.pathname.startsWith(routePattern);
    };

    const handleSearchClick = () => {
      if (!company.activo) {
          alert("La empresa no está activa.");
      } else {
          navigate('/form-busqueda');
      }
  };

    // Patrones
    const profilePattern = '/home';
  
    return (
      <>
      <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap' rel='stylesheet'></link>
      <header>
        <img className="logo" src={require('../../img/WoT.png')} alt="Logo" width="150" />
        <nav className='header_navigation'>
          <ul>
            {isLoggedIn && (
              <>
                <li>
                  <a href="/home" className="navlink">
                    <div className="icon-container">
                      <HomeIcon className={isActive(profilePattern) ? 'profile_icon activo' : 'profile_icon'} />
                      <span className={isActive(profilePattern) ? 'navlink-text activo' : 'navlink-text'}>Inicio</span>
                    </div>
                  </a>
                </li>
                <li>
                    <button className="navlink" onClick={handleSearchClick}>
                        <div className="icon-container">
                            <SearchIcon className='navlink_icon' />
                            <span className='navlink-text'>Buscar</span>
                        </div>
                    </button>
                </li>
                <li>
                    <button className="navlink" onClick={handleOpenLogoutModal}>
                        <div className="icon-container">
                            <LuLogOut className='navlink_icon' size={26} />
                            <span className='navlink-text'>Cerrar Sesión</span>
                        </div>
                    </button>
                    <LogoutModal isOpen={isLogoutModalOpen} onClose={handleCloseLogoutModal} onLogout={handleLogout} />
                </li>
              </>
            )}
          </ul>
        </nav>
      </header></>
    );
  }
  
  export default Header;
  