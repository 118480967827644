import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import './Busqueda.css'
import Circulo from '../../img/Circulo.png';
import { useNavigate } from 'react-router-dom';

const Busqueda1 = () => {

    const [cargos, setCargos] = useState([]);
    const [jornadas, setJornadas] = useState([]);
    const [modalidades, setModalidades] = useState([]);
    const [areas, setAreas] = useState([]);
    const [regiones, setRegiones] = useState([]);

    const [cargoBuscado , setCargoBuscado] = useState(JSON.parse(localStorage.getItem("cargoBuscado") || '""'));
    const [jornadaBuscada , setJornadaBuscada] = useState(JSON.parse(localStorage.getItem("jornadaBuscada") || 'null'));
    const [modalidadBuscada, setModalidadBuscada] = useState(JSON.parse(localStorage.getItem("modalidadBuscada") || 'null'));
    const [areaBuscada, setAreaBuscada] = useState(JSON.parse(localStorage.getItem("areaBuscada") || 'null'));
    const [regionBuscada, setRegionBuscada] = useState(JSON.parse(localStorage.getItem("regionBuscada") || 'null'));
    const [horasRequeridas, setHorasRequeridas] = useState(JSON.parse(localStorage.getItem("horasRequeridas") || '0'));
    const [trabajoFlexible, setTrabajoFlexible] = useState(JSON.parse(localStorage.getItem("trabajoFlexible") || '0'));
    const [nombreBusqueda, setNombreBusqueda] = useState(localStorage.getItem("nombreBusqueda") || '');
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/obtenerListas/disponibilidades`)
        .then(response => response.json())
        .then(data => {
            const cargos = data.map((index) => {
                return {value: index.id , label:index.disponibilidad}
            })
            setCargos(cargos)
        })
        fetch(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/obtenerListas/jornadas`)
        .then(response => response.json())
        .then(data => {
            const jornadas = data.slice(0, 2).map((index) => {
                return { value: index.id, label: index.tipoJornada };
              });  
            setJornadas(jornadas)
        })
        fetch(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/obtenerListas/modalidades`)
        .then(response => response.json())
        .then(data => {
            const modalidades = data.map((index) => {
                return {value: index.id , label:index.tipoModalidad}
            })
            setModalidades(modalidades)
        })
        fetch(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/obtenerListas/areas`)
        .then(response => response.json())
        .then(data => {
            const areas = data.map((index) => {
                return {value: index.id , label:index.nombre}
            })
            setAreas(areas)
        })
        fetch(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/obtenerListas/regiones`)
        .then(response => response.json())
        .then(data => {
            const regiones = data.map((index) => {
                return {value: index.id , label:index.nombre}
            })
            setRegiones(regiones)
        })
    }, [])

    const handleCargoBuscado= (e) => {
        setCargoBuscado(e.target.value)
        localStorage.setItem("cargoBuscado", JSON.stringify(e.target.value));
    };
    const handleJornadaBuscada= (e) => {
        setJornadaBuscada(e.target.value)
        localStorage.setItem("jornadaBuscada", JSON.stringify(e.target.value));
    };
    const handleModalidadBuscada= (e) => {
        setModalidadBuscada(e.target.value)
        localStorage.setItem("modalidadBuscada", JSON.stringify(e.target.value));
    };
    const handleAreaBuscada= (e) => {
        setAreaBuscada(e.target.value)
        localStorage.setItem("areaBuscada", JSON.stringify(e.target.value));
    };
    const handleRegionBuscada= (e) => { 
        setRegionBuscada(e.target.value)
        localStorage.setItem("regionBuscada", JSON.stringify(e.target.value));
    };
    const handleTrabajoFlexible= (e) => {
        setTrabajoFlexible(e.target.value)
        localStorage.setItem("trabajoFlexible", JSON.stringify(e.target.value));
    };
    const handleHorasRequeridas= (e) => {
        const horas = parseInt(e.target.value)
        setHorasRequeridas(horas)
        localStorage.setItem("horasRequeridas", JSON.stringify(horas));
    };
    
    const handleSubmit = async (e) => {
        
        // Asegura que el nombre de la búsqueda no está vacío
        if (!nombreBusqueda.trim()) {
            alert("Por favor, completa todos los campos obligatorios.");
            return;
        }
    
        if (cargoBuscado === '1') {
            // Si es cargoBuscado '1', todos los campos marcados con * deben estar llenos
            if (!jornadaBuscada || !modalidadBuscada || !areaBuscada || !regionBuscada) {
                alert("Por favor, completa todos los campos obligatorios.");
                return;
            }
        } else {
            // Si el cargoBuscado no es '1', solo verifica que cargoBuscado no esté vacío
            if (!cargoBuscado) {
                alert("Por favor, selecciona un cargo.");
                return;
            }
        }

        localStorage.setItem("nombreBusqueda", nombreBusqueda);
        navigate('/form-busqueda2');
    }
    return (
    <div className="WT1">
        <label className='numero1-busquedas' id ='active'>1</label>
        <label className='numero2-busquedas'>2</label>
        <label className='numero3-busquedas'>3</label>
        <label className='busqueda-busquedas'>Búsqueda</label>
        <label className='perfilProfesional-busquedas'>Perfil profesional</label>
        <label className='requisitos-busquedas'>Requisitos</label>
        <div className='container-busquedas'>
            <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap' rel='stylesheet'></link>
            <h3 className='texto1-busquedas'>¡Estas haciendo tu formulario de búsqueda!</h3> 
            <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='nombreBusqueda'>
                            <label>Nombre de la búsqueda <span className='rojo'>*</span></label>
                            <div></div>
                            <input
                                name="nombre"
                                component="input"
                                type="text"
                                value={nombreBusqueda}
                                onChange={(e) => setNombreBusqueda(e.target.value)}
                                className='input-nombre'
                            />
                        </div>
                        <div className='cargoBuscado'>
                            <label> ¿Para qué posición o cargo buscas a una mujer profesional? <span className='rojo'>*</span></label>
                            <select value={cargoBuscado} onChange={handleCargoBuscado} className='input-busquedas select-dropdown'>
                                <option value=""></option>
                                {cargos.map((index)=>{
                                return(
                                <option value={index.value}>{index.label}</option>
                                );
                                })}
                            </select>
                        </div>
                        { cargoBuscado === '1' ? (
                        <div className='jornadaBuscada'>
                            <label> Tipo de jornada asociada al cargo <span className='rojo'>*</span></label>
                            <select value={jornadaBuscada} onChange={handleJornadaBuscada} className='input-jornada'>
                                <option value=""></option>
                                {jornadas.map((index)=>{
                                return(
                                <option value={index.value}>{index.label}</option>
                                );
                                })}
                            </select>
                        </div>
                        ) : (null)}
                        { cargoBuscado === '1' ? (
                        <div className='modalidadBuscada'>
                            <label> Modalidad <span className='rojo'>*</span></label>
                            <select value={modalidadBuscada} onChange={handleModalidadBuscada} className='input-modalidad'>
                                <option value=""></option>
                                {modalidades.map((index)=>{
                                return(
                                <option value={index.value}>{index.label}</option>
                                );
                                })}
                            </select>
                        </div>
                        ) : (null)}
                        { cargoBuscado === '1' ? (
                        <div className='areaBuscada'>
                            <label> Área de la empresa para la que buscas la profesional <span className='rojo'>*</span></label>
                            <select value={areaBuscada} onChange={handleAreaBuscada} className='input-area'>
                                <option value=""></option>
                                {areas.map((index)=>{
                                return(
                                <option value={index.value}>{index.label}</option>
                                );
                                })}
                            </select>
                        </div>
                        ) : (null)}
                        { cargoBuscado === '1' ? (
                        <div className='regionBuscada'>
                            <label> Región del puesto <span className='rojo'>*</span></label>
                            <select value={regionBuscada} onChange={handleRegionBuscada} className='input-region'>
                                <option value=""></option>
                                {regiones.map((index)=>{
                                return(
                                <option value={index.value}>{index.label}</option>
                                );
                                })}
                            </select>
                        </div>
                        ) : (null)}
                        { jornadaBuscada === '2' ? (
                             <div className='horasRequeridas'>
                             <label>Número de horas diarias requeridas</label>
                             <div></div>
                             <input
                                 name="horas"
                                 component="input"
                                 type="text"
                                 value={horasRequeridas}
                                 onChange={handleHorasRequeridas}
                                 className='input-horas'
                                />
                            </div>
                        ) : (null)}
                        { cargoBuscado === '1' ? (
                        <div className='trabajoFlexible'>
                            <label>¿El cargo considera días libres sobre lo legal, u otra forma de trabajo flexible?</label>
                            <div></div>
                            <select value ={trabajoFlexible} onChange={handleTrabajoFlexible} className='input-trabajo'> 
                                <option value=""></option>
                                <option value={true}>Si</option>
                                <option value={false}>No</option>
                            </select>
                        </div>
                        ) : (null)}
                    </form>   
                )}
            />
            <progress id="barra" max="100" value="25"> 25% </progress> 
            <label className='porcentaje'>25%</label>
            <button id='siguiente-busquedas' onClick={(e) => {handleSubmit()} }>
                Siguiente
            </button>
            
        </div>
        <div className='rectangulo-busquedas'></div>
        <img src={Circulo} alt='circulo' className='wot-circulo' />
    </div>
    )  
}

export default Busqueda1;