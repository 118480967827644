import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import './Home.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

const FindProfesion = async (id_usuaria) => {
  const jwtToken = localStorage.getItem('accessToken');
  try {
    const response = await axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/obtenerids/usuaria/profesion/${id_usuaria}`, {
      headers: {
        'Authorization': 'Bearer ' + jwtToken,
        'Content-Type': 'application/json'
      },
    });
    return response.data;
  } catch (error) {
    console.error("Hubo un error en la petición: ", error);
    return null;
  }
};

const FindIndustrias = async (id_usuaria) => {
  const jwtToken = localStorage.getItem('accessToken');
  try {
    const response = await axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/obtenerids/usuaria/industria/${id_usuaria}`, {
      headers: {
        'Authorization': 'Bearer ' + jwtToken,
        'Content-Type': 'application/json'
      },
    });
    return response.data;
  } catch (error) {
    console.error("Hubo un error en la petición: ", error);
    return null;
  }
};

const BusquedaItem = ({ matchs, handleCheckboxChange, contador }) => {
  const [profesiones, setProfesiones] = useState('');
  const [industrias, setIndustrias] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const profesionData = await FindProfesion(matchs.usuaria.id);
        const industriaData = await FindIndustrias(matchs.usuaria.id);
        // Limitando las profesiones a 3 y agregando "..."
        const profesionesFormatted = profesionData
          ? profesionData.slice(0, 3).map(p => p.profesion.nombre).join(", ") + (profesionData.length > 2 ? "..." : "")
          : "No especificadas";
        // Limitando las industrias a 3 y agregando "..."
        const industriasFormatted = industriaData
          ? industriaData.slice(0, 2).map(i => i.industria.nombre_industria).join(", ") + (industriaData.length > 2 ? "..." : "")
          : "No especificadas";

        setProfesiones(profesionesFormatted);
        setIndustrias(industriasFormatted);
      } catch (error) {
        console.error("Error fetching data for match", error);
      }
    };
    fetchData();
  }, [matchs.usuaria.id]);

  return (
    <div className="subcontainerMiPerfil">
      <input
        type="checkbox"
        className="input-checkbox"
        checked={matchs.isSelected}
        onChange={() => handleCheckboxChange(matchs.id, matchs.isSelected)}
      />
      <div className="box-por">
        <div className="num-por">{(matchs.correspondencia * 100).toFixed(0)}%</div>
      </div>
      <div className="subcontainerDetalle">
        <h3 className='nombre-detalle'>Candidata {contador}</h3>
        <h3 className='otro-detalle'>Profesiones: {profesiones}</h3>
        <h3 className='otro-detalle2'>Industrias: {industrias}</h3>
      </div>
      <Link id="detalle" to={{ pathname: `/match/${matchs.id}/${contador}` }}>Ver Detalle</Link>
    </div>
  );
};

function Detalle() {
  const jwtToken = localStorage.getItem('accessToken');
  const [matchs, setMatchs] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [busqueda, setBusqueda] = useState(null);
  const [selected, setSelected] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedResults, setSelectedResults] = useState([]);

  const saveSelectedCheckboxes = (selected) => {
    localStorage.setItem('selectedCheckboxes', JSON.stringify(selected));
  };

  const loadSelectedCheckboxes = () => {
    const selected = localStorage.getItem('selectedCheckboxes');
    return selected ? JSON.parse(selected) : [];
  };

  const sendSlackMessage = (message) => {
    //const backendUrl = `http://localhost:3001/api/slack/enviar-mensaje-slack`;
    const backendUrl = `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/slack/enviar-mensaje-slack`;
    const payload = {
      message: message,
    };
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
    };
    axios
      .post(backendUrl, payload, { headers })
      .then((response) => {
        alert("Estimado cliente, hemos recibido tu solicitud para hacer match para tu proceso de búsqueda. Validaremos los datos de las candidatas y te enviamos la información a la brevedad.");
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error al enviar el mensaje a Slack desde el frontend:', error.message);
        alert("No se ha podido enviar los matchs solicitados. Por favor, inténtalo de nuevo.");
      });
  };

  const handleDesbloquearClick = () => {
    const selected = matchs.filter(match => match.isSelected);
    setSelectedResults(selected);
    sendSlackMessage(selected);
    localStorage.removeItem('selectedCheckboxes');
  };

  const formatearFecha = (fecha) => {
    const fechaObj = new Date(fecha);
    const dia = fechaObj.getDate();
    const mes = fechaObj.toLocaleString('es-ES', { month: 'long' });
    const ano = fechaObj.getFullYear();
    return `${dia} de ${mes} de ${ano}`;
  };

  useEffect(() => {
    const jwtToken = Cookies.get('jwtToken');
    if (jwtToken) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
      navigate("/");
    }
    const initialSelected = loadSelectedCheckboxes();
    setSelected(initialSelected);
  }, []);

  const handleCheckboxChange = (id, isSelected) => {
    const updatedMatchs = matchs.map(match => {
      if (match.id === id) {
        match.isSelected = !isSelected;
      }
      return match;
    });

    const updatedSelected = updatedMatchs.filter(match => match.isSelected).map(match => match.id);
    setSelected(updatedSelected);
    saveSelectedCheckboxes(updatedSelected);
    setMatchs(updatedMatchs);
  };

  useEffect(() => {
    if (!jwtToken) {
      navigate('/login');
    }
  }, [jwtToken, navigate]);

  useEffect(() => {
    axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/obtenerids/matchs/${id}`, {
      headers: {
        authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        const data = response.data;
        const initialSelected = loadSelectedCheckboxes();
        const updatedMatchs = data.map(match => ({
          ...match,
          isSelected: initialSelected.includes(match.id),
        }));
        setMatchs(updatedMatchs);
      })
      .catch(error => {
        console.log(error);
      });
  }, [id, jwtToken]);

  useEffect(() => {
    axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/busqueda/${id}`, {
      headers: {
        authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        const data = response.data;
        setBusqueda(data);
      })
      .catch(error => {
        console.error("Error fetching busqueda data:", error);
      });
  }, [id, jwtToken]);

  if (!busqueda) return 'Cargando...';

  return (
    <>
      <div className='div_profile'></div>
      {isLoggedIn && (
        <div className="desktop_perfil">
          <div className="div">
            <div className="overlap-group">
              <div className="rectangulo_principal">
                <div className="rectangulo_seccion">
                  <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap' rel='stylesheet'></link>
                  <h3 className='message-home'>Búsquedas: {matchs.length} Match</h3>
                  <div className="rectangle2"></div>
                  <div className='busquedas'>
                    <h3 className='message-home2'>Resultados Búsqueda</h3>
                    {matchs.map((matchs, index) => <BusquedaItem key={index} matchs={matchs} handleCheckboxChange={handleCheckboxChange} contador={index + 1} />)}
                  </div>
                </div>
              </div>
              <div className='rectangulo-arriba'>
                <h3 className='message-home3'>Información de búsqueda</h3>
                <div className="rectangle3"></div>
                <h3 className='message-home2'>{busqueda.nombre}</h3>
                <div className="rectangle3"></div>
                <h3 className='busqueda-title'>Fecha: {formatearFecha(busqueda.fecha)}</h3>
                {busqueda.cargo && (
                  <>
                    <h3 className='busqueda-title'>Cargo:</h3>
                    <h3 className='busqueda-resp'>{busqueda.cargo.disponibilidad}</h3>
                  </>
                )}
                {busqueda.areas?.length > 0 && (
                  <>
                    <h3 className='busqueda-title'>Area:</h3>
                    <h3 className='busqueda-resp'>{busqueda.areas[0].nombre}</h3>
                  </>
                )}
                {busqueda.profesiones?.length > 0 && (
                  <>
                    <h3 className='busqueda-title'>Profesiones:</h3>
                    <h3 className='busqueda-resp'>{busqueda.profesiones[0].nombre}</h3>
                  </>
                )}
                {busqueda.jornada && (
                  <>
                    <h3 className='busqueda-title'>Jornada:</h3>
                    <h3 className='busqueda-resp'>{busqueda.jornada.tipoJornada}</h3>
                  </>
                )}
                {busqueda.modalidad && (
                  <>
                    <h3 className='busqueda-title'>Modalidad:</h3>
                    <h3 className='busqueda-resp'>{busqueda.modalidad.tipoModalidad}</h3>
                  </>
                )}
              </div>
              <div className='rectangulo-desbloquear'>
                <h3 className='texto-rectangulo-desbloquear'>Desbloquear Resultados</h3>
                <h3 className='busqueda-resp'>Selecciona los match que quieres desbloquear y nosotros las contactaremos.</h3>
                {selected.length !== 0 ? (
                  <a id="desbloquear-busqueda" onClick={handleDesbloquearClick}>Desbloquear y Enviar</a>
                ) : null}
                <button id="atras-detalles" onClick={() => navigate('/home')}>
                  Volver Atrás
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Detalle;