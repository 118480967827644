import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import './Busqueda.css'
import { useNavigate } from 'react-router-dom';
import Circulo from '../../img/Circulo.png';

const Busqueda3 = () => {
    
    const [altaDireccionBuscada, setAltaDireccion] = useState(JSON.parse(localStorage.getItem("altaDireccionBuscada") || 'null'));
    const [experienciaDirectoriosBuscada, setExperienciaDirectorios] = useState(JSON.parse(localStorage.getItem("experienciaDirectoriosBuscada") || 'null'));
    const [necesidadViaje, setNecesidadViaje] = useState(JSON.parse(localStorage.getItem("necesidadViaje") || '0'));
    const [rangoAnos , setRangoAnos] = useState([]);
    const [anosBuscados, setAnos] = useState(JSON.parse(localStorage.getItem("anosBuscados") || '[]'));
    const navigate = useNavigate();
    const cargoBuscado = JSON.parse(localStorage.getItem("cargoBuscado"));

    useEffect(() => {    
        fetch(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/obtenerListas/rangoAnos`)
        .then(response => response.json())
        .then(data => {
            const rangoAnos = data.map((index) => {
                return {value: index.id , label:index.rango}
            })
            setRangoAnos(rangoAnos)
        })
    }, [])

    const handleAltaDireccion = (e) => {
        setAltaDireccion(e.target.value);
        localStorage.setItem("altaDireccionBuscada", JSON.stringify(e.target.value));
    };
    const handleExperienciaDirectorios = (e) => {
        setExperienciaDirectorios(e.target.value);
        localStorage.setItem("experienciaDirectoriosBuscada", JSON.stringify(e.target.value));
    };
    const handleNecesidadViaje= (e) => {
        setNecesidadViaje(e.target.value);
        localStorage.setItem("necesidadViaje", JSON.stringify(e.target.value));
    };
    const handleRangoAnos = (e) => {
        setAnos(e.target.value);
        localStorage.setItem("anosBuscados", JSON.stringify(e.target.value));
    };
    function busqueda2() {
        navigate('/form-busqueda2');
    }
    
    const handleSubmit= async(e) =>{
        if (anosBuscados.length === 0) {
            alert("Por favor, completa todos los campos obligatorios.");
            return;
        } else{
            navigate('/form-busqueda4');
        }
    }
    return (
    <div className="WT1">
        <label className='numero1-busquedas'>1</label>
        <label className='numero2-busquedas'>2</label>
        <label className='numero3-busquedas' id = 'active'>3</label>
        <label className='busqueda-busquedas'>Búsqueda</label>
        <label className='perfilProfesional-busquedas'>Perfil profesional</label>
        <label className='requisitos-busquedas'id = 'negrita-busquedas'>Requisitos</label>
        <div className='container-busquedas'>
            <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap' rel='stylesheet'></link>
            <h3 className='texto1-busquedas'>¡Estas haciendo tu formulario de búsqueda!</h3> 
            <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='altaDireccion'>
                            <label>¿Es necesario que la candidata tenga formación en alta dirección?</label>
                            <div></div>
                            <select value={altaDireccionBuscada} onChange={handleAltaDireccion} className='input-ad'>
                                <option value=""></option>
                                <option value={true}>Si</option>
                                <option value={false}>No</option>
                            </select>
                        </div>
                        <div className='experienciaDirectorios'>
                            <label>¿Es necesario que la candidata tenga experiencia en directorios?</label>
                            <div></div>
                            <select value={experienciaDirectoriosBuscada} onChange={handleExperienciaDirectorios} className='input-ed'>
                                <option value=""></option>
                                <option value={true}>Si</option>
                                <option value={false}>No</option>
                            </select>
                        </div>
                        {cargoBuscado === '1' ? (
                        <div className='necesidadViaje'>
                            <label>¿Necesitas que la candidata viaje?</label>
                            <div></div>
                            <select value={necesidadViaje} onChange={handleNecesidadViaje} className='input-viaje'> 
                                <option value=""></option>
                                <option value={true}>Si</option>
                                <option value={false}>No</option>
                            </select>
                        </div>
                        ) : (null)}
                        <div className='anosBuscados'>
                            <label> Indique el rango de años de experiencia buscado <span className='rojo'>*</span></label>
                            <select value={anosBuscados} onChange={handleRangoAnos} className='input-rangoAnos'>
                                <option value=""></option>
                                {rangoAnos.map((index)=>{
                                return(
                                <option value={index.value}>{index.label}</option>
                                );
                                })}
                            </select>
                        </div>
                    </form>   
                )}
            />
            <progress id="barra" max="100" value="75"> 75% </progress> 
            <label className='porcentaje'>75%</label>
            <button id='siguiente-busquedas' onClick={(e) => {handleSubmit()} }>
                Siguiente
            </button>
            <button id='atras-busquedas' onClick={(e) => {busqueda2()} }> Atras </button>
        </div>
        <div className='rectangulo-busquedas'></div>
        <img src={Circulo} alt='circulo' className='wot-circulo' />
    </div>
    )
}

export default Busqueda3;