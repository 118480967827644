import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Login from './components/Auth/Login';
import ForgotPassword from './components/Auth/ForgotPassword';
import EnterVerificationCode from './components/Auth/EnterVerificationCode';
import Home from './components/Home/Home';
import Header from './components/Header/Header';
import Busqueda1 from './components/Busqueda/busqueda1';
import Busqueda2 from './components/Busqueda/busqueda2';
import Busqueda3 from './components/Busqueda/busqueda3';
import Busqueda4 from './components/Busqueda/busqueda4';
import Busqueda5 from './components/Busqueda/busqueda5';
import Busqueda6 from './components/Busqueda/busqueda6';
import Detalle from './components/Home/Detalle';
import Match from './components/Perfil/Match';

function Log() {
  return( <div className='App'>
            <Login />
          </div>
  );
}

function Hm() {
  return( <div className='App'>
            <Header />
            <Home />
          </div>
  );
}

function Form() {
  return( <div className='App'>
            <Header />
            <Busqueda1 />
          </div>
  );
}

function Form2() {
  return( <div className='App'>
            <Header />
            <Busqueda2 />
          </div>
  );
}

function Form3() {
  return( <div className='App'>
            <Header />
            <Busqueda3 />
          </div>
  );
}

function Form4() {
  return( <div className='App'>
            <Header />
            <Busqueda4 />
          </div>
  );
}

function Form5() {
  return( <div className='App'>
            <Header />
            <Busqueda5 />
          </div>
  );
}

function Form6() {
  return( <div className='App'>
            <Header />
            <Busqueda6 />
          </div>
  );
}

function Busqueda() {
  return( <div className='App'>
            <Header />
            <Detalle />
          </div>
  );
}

function Mat() {
  return( <div className='App'>
            <Header />
            <Match />
          </div>
  );
}


function App() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderContent = () => {

    return (
      <Router>
        <Routes>
          <Route path="/" element={<Log />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/enterverificationcode" element={<EnterVerificationCode />} />
          <Route path="/home" element={<Hm />} />
          <Route path= "/form-busqueda" element={<Form/>} />
          <Route path= "/form-busqueda2" element={<Form2/>} />
          <Route path= "/form-busqueda3" element={<Form3/>} />
          <Route path= "/form-busqueda4" element={<Form4/>} />
          <Route path= "/form-busqueda5" element={<Form5/>} />
          <Route path= "/form-busqueda6" element={<Form6/>} />
          <Route path="/busqueda/:id" element={<Busqueda/>} />
          <Route path="/match/:id/:contador" element={<Mat/>} />
        </Routes>
      </Router>
    );
  };
  return (
    <>{renderContent()}</>
  );
}

export default App;