import React from 'react';
import Modal from 'react-modal';
import '../Header/Header.css';

function LogoutModal({ isOpen, onClose, onLogout }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal-content"
    >
      <p>¿Estás seguro que deseas cerrar la sesión?</p>
      <div className="modal-buttons">
        <button onClick={onClose}>Cancelar</button>
        <button onClick={onLogout}>Cerrar Sesión</button>
      </div>
    </Modal>
  );
}
export default LogoutModal;