import React, { useEffect, useState } from 'react';
import './Busqueda.css';
import { useNavigate } from 'react-router-dom';
import Circulo from '../../img/Circulo.png';
import { Form } from 'react-final-form';
import Select from "react-select";
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import confetti from 'canvas-confetti';

const Busqueda4 = () => {
    const jwtToken = localStorage.getItem('accessToken');
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const companyId = user?.data?.attributes?.empresa?.id || null;
    const [competencias, setCompetencias] = useState([]);
    const [idiomas, setIdiomas] = useState([]);
    const [personalidad, setPersonalidad] = useState([]);
    const [competencia, setCompetencia] = useState(JSON.parse(localStorage.getItem("competenciasBuscadas") || '[]'));
    const [formularioPerso, setFormularioPerso] = useState(localStorage.getItem("formularioPersoBuscado") || "");
    const [idioma, setIdioma] = useState(JSON.parse(localStorage.getItem("idiomaBuscado") || '[]'));
    const [postgrado, setPostgrado] = useState(localStorage.getItem("postgradoBuscado") || null);
    const [flexible, setFlexible] = useState(false);
    const [necesidad, setNecesidad] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [candidatasCount, setCandidatasCount] = useState(0);
    const [searchingCandidates, setSearchingCandidates] = useState(true);
    const [showConfetti, setShowConfetti] = useState(false);

    const cargoBuscado = JSON.parse(localStorage.getItem("cargoBuscado") || 'null');
    const regionBuscada = JSON.parse(localStorage.getItem("regionBuscada") || 'null');
    const jornadaBuscada = JSON.parse(localStorage.getItem("jornadaBuscada") || 'null');
    const modalidadBuscada = JSON.parse(localStorage.getItem("modalidadBuscada") || 'null');
    const areaBuscada = JSON.parse(localStorage.getItem("areaBuscada") || 'null');
    const horasRequeridas = JSON.parse(localStorage.getItem("horasRequeridas") || 'null');
    const trabajoFlexible = JSON.parse(localStorage.getItem("trabajoFlexible") || 'null');
    const necesidadViaje = JSON.parse(localStorage.getItem("necesidadViaje") || 'null');
    const profesionBuscada = JSON.parse(localStorage.getItem("profesionBuscada") || '[]');
    const anosBuscados = JSON.parse(localStorage.getItem("anosBuscados") || 'null');
    const areasBuscadas = JSON.parse(localStorage.getItem("areasBuscadas") || '[]');
    const sectorBuscado = JSON.parse(localStorage.getItem("sectorBuscado") || '[]');
    const altaDireccionBuscada = JSON.parse(localStorage.getItem("altaDireccionBuscada") || 'null');
    const experienciaDirectoriosBuscada = JSON.parse(localStorage.getItem("experienciaDirectoriosBuscada") || 'null');
    const [altaDireccion, setAltaDireccion] = useState(altaDireccionBuscada === "true");
    const [experienciaDirectorios, setExperienciaDirectorios] = useState(experienciaDirectoriosBuscada === "true");
    const nombreBusqueda = localStorage.getItem("nombreBusqueda") || '';
    const [postgradoBuscado, setPostgradoBuscado] = useState(postgrado === "true");
    const uri = `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/busqueda`;
    const profesiones = [];
    const sectores = [];
    const areas = [];
    const competenciasBuscadas = [];
    const idiomasBuscados = [];

    useEffect(() => {
        fetch(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/obtenerListas/competencias`)
            .then(response => response.json())
            .then(data => {
                const competencias = data.map((index) => {
                    return { value: index.id, label: index.competencia };
                });
                setCompetencias(competencias);
            });
        fetch(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/obtenerListas/idiomas`)
            .then(response => response.json())
            .then(data => {
                const idiomas = data.map((index) => {
                    return { value: index.id, label: index.nombre };
                });
                setIdiomas(idiomas);
            });
        fetch(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/obtenerListas/formularios`)
            .then(response => response.json())
            .then(data => {
                const personalidad = data.map((index) => {
                    return { value: index.id, label: index.personalidad };
                });
                setPersonalidad(personalidad);
            });
    }, []);

    const handlePostgrado = (e) => {
        setPostgrado(e.target.value);
        localStorage.setItem("postgradoBuscado", e.target.value);
    }
    const handleCompetencia = (selectedOptions) => {
        if (selectedOptions.length <= 5) {
            setCompetencia(selectedOptions);
            localStorage.setItem("competenciasBuscadas", JSON.stringify(selectedOptions));
        } else {
            alert("Solo puedes seleccionar hasta 5 competencias.");
        }
    }
    const handleIdioma = (selectedOptions) => {
        setIdioma(selectedOptions);
        localStorage.setItem("idiomaBuscado", JSON.stringify(selectedOptions));
    }
    const handlePersonalidad = (e) => {
        setFormularioPerso(e.target.value);
        localStorage.setItem("formularioPersoBuscado", e.target.value);
    }
    const navigate = useNavigate();

    function busqueda3() {
        navigate('/form-busqueda3');
    }

    const fetchLatestBusqueda = async (companyId, jwtToken) => {
        try {
            const response = await axios.get(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/api/busqueda/empresa/${companyId}`, {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`,
                    'Content-Type': 'application/json'
                }
            });
            const busquedas = response.data;
            if (busquedas.length > 0) {
                return busquedas.reduce((max, busqueda) => busqueda.busquedaId > max.busquedaId ? busqueda : max, busquedas[0]);
            } else {
                throw new Error("No busquedas found");
            }
        } catch (error) {
            console.error('Error fetching latest busqueda:', error);
            return null;
        }
    }

    const handleFetch = async (uri, infoBusquedaJSON) => {
        await fetch(uri, {
            method: "post",
            headers: {
                "Authorization": `Bearer ${jwtToken}`,
                "Content-type": "application/json"
            },
            body: infoBusquedaJSON
        }).then(function (response) {
        }).catch((error) => {
            alert("Error al enviar el formulario de búsqueda");
            console.error('Error:', error);
        });
    }

    const handleSubmit = async (e) => {
        setIsLoading(true);
        if (competencia.length === 0 || idioma.length === 0) {
            alert("Por favor, completa todos los campos obligatorios.");
            setIsLoading(false);
            return;
        } else {
            profesionBuscada.forEach((element) => {
                profesiones.push(element.value);
            });
            sectorBuscado.forEach((element) => {
                sectores.push(element.value);
            });
            competencia.forEach((element) => {
                competenciasBuscadas.push(element.value);
            });
            areasBuscadas.forEach((element) => {
                areas.push(element.value);
            });
            idioma.forEach((element) => {
                idiomasBuscados.push(element.value);
            });
            if (trabajoFlexible === "true") {
                setFlexible(true);
            } else {
                setFlexible(false);
            }
            if (necesidadViaje === "true") {
                setNecesidad(true);
            } else {
                setNecesidad(false);
            }
            if (altaDireccionBuscada === "true") {
                setAltaDireccion(true);
            } else {
                setAltaDireccion(false);
            }
            if (experienciaDirectoriosBuscada === "true") {
                setExperienciaDirectorios(true);
            } else {
                setExperienciaDirectorios(false);
            }
            if (postgrado === "true") {
                setPostgrado(true);
            } else {
                setPostgrado(false);
            }
            const fecha = new Date();
            var infoBusqueda = {
                nombre: nombreBusqueda,
                fecha: fecha,
                id_empresa: companyId,
                id_cargo: parseInt(cargoBuscado),
                id_jornada: parseInt(jornadaBuscada),
                horas_requetidas: horasRequeridas,
                id_modalidad: parseInt(modalidadBuscada),
                area_especifica: true,
                area: parseInt(areaBuscada),
                regionEspecifica: true,
                id_region: parseInt(regionBuscada),
                cargo_flexible: flexible,
                necesidad_viaje: necesidad,
                profesionEspecifica: true,
                postgrado: postgradoBuscado,
                id_anios_experiencia: parseInt(anosBuscados),
                sectorIndustriaEspecifica: "Agricola",
                id_industria: 2,
                experiencia_directorios: experienciaDirectorios,
                alta_direccion: altaDireccion,
                profesiones: profesiones,
                industrias: sectores,
                areasExperiencia: areas,
                competencias: competenciasBuscadas,
                idiomas: idiomasBuscados
            }
            const infoBusquedaJSON = JSON.stringify(infoBusqueda);
            await handleFetch(uri, infoBusquedaJSON);

            // Limpiar los datos del localStorage específicos del formulario
            localStorage.removeItem("nombreBusqueda");
            localStorage.removeItem("cargoBuscado");
            localStorage.removeItem("jornadaBuscada");
            localStorage.removeItem("modalidadBuscada");
            localStorage.removeItem("areaBuscada");
            localStorage.removeItem("regionBuscada");
            localStorage.removeItem("horasRequeridas");
            localStorage.removeItem("trabajoFlexible");
            localStorage.removeItem("necesidadViaje");
            localStorage.removeItem("profesionBuscada");
            localStorage.removeItem("anosBuscados");
            localStorage.removeItem("areasBuscadas");
            localStorage.removeItem("sectorBuscado");
            localStorage.removeItem("altaDireccionBuscada");
            localStorage.removeItem("experienciaDirectoriosBuscada");
            localStorage.removeItem("competenciasBuscadas");
            localStorage.removeItem("formularioPersoBuscado");
            localStorage.removeItem("idiomaBuscado");
            localStorage.removeItem("postgradoBuscado");

            const latestBusqueda = await fetchLatestBusqueda(companyId, jwtToken);
            if (latestBusqueda) {
                setSearchingCandidates(false);
                let count = 0;
                const maxCandidates = Math.min(latestBusqueda.numDeMatch, 20);
                const totalTime = Math.random() * (10 - 5) + 5; // Tiempo random entre 5 y 10 segundos
                const intervalTime = (totalTime / maxCandidates) * 1000; // Tiempo en milisegundos
    
                const intervalId = setInterval(() => {
                    count++;
                    setCandidatasCount(count);
                    if (count >= maxCandidates) {
                        clearInterval(intervalId);
                        setShowConfetti(true);
                        setTimeout(() => {
                            setShowConfetti(false);
                            confetti({
                                particleCount: 200,
                                spread: 160,
                                origin: { y: 0.6 }
                            });
                            setTimeout(() => {
                                window.location.href = `/busqueda/${latestBusqueda.busquedaId}`;
                            }, 2000);
                        }, 1000);
                    }
                }, intervalTime);
            }
        }
    }

    return (
        <div className="WT1">
            {isLoading && (
                <div className="loading-overlay">
                    {showConfetti ? (
                        <p> </p>
                    ) : (
                        <>
                            <CircularProgress size={50} />
                            <p>{searchingCandidates ? "Estamos buscando a tus candidatas..." : `${candidatasCount} candidatas encontradas`}</p>
                        </>
                    )}
                </div>
            )}
            <div className={isLoading ? 'blurred' : ''}>
                <label className='numero1-busquedas'>1</label>
                <label className='numero2-busquedas'>2</label>
                <label className='numero3-busquedas' id='active'>3</label>
                <label className='busqueda-busquedas'>Búsqueda</label>
                <label className='perfilProfesional-busquedas'>Perfil profesional</label>
                <label className='requisitos-busquedas' id='negrita-busquedas'>Requisitos</label>
                <div className='container-busquedas'>
                    <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap' rel='stylesheet'></link>
                    <h3 className='texto1-busquedas'>¡Estas haciendo tu formulario de búsqueda!</h3>
                    <Form
                        onSubmit={handleSubmit}
                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                            <form onSubmit={handleSubmit}>
                                <div className='postgrado-busquedas'>
                                    <label>Quieres que tenga postgrado</label>
                                    <div></div>
                                    <select value={postgrado} onChange={handlePostgrado} className='input-postgrado'>
                                        <option value=""></option>
                                        <option value={true}>Si</option>
                                        <option value={false}>No</option>
                                    </select>
                                </div>
                                <div className='resultadoPerso'>
                                    <label> Qué características de personalidad estimas son más compatibles con el cargo y la organización? </label>
                                    <select value={formularioPerso} onChange={handlePersonalidad} className='input-personalidad'>
                                        <option value=""></option>
                                        {personalidad.map((index) => {
                                            return (
                                                <option key={index.value} value={index.value}>{index.label}</option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className='competencias-busquedas'>
                                    <label> Competencias <span className='rojo'>*</span></label>
                                    <div></div>
                                    <label className='ejemplo-area'><span className='rojo'> Seleccionar máximo 5 respuestas </span></label>
                                    <div></div>
                                    <Select
                                        className='input-competencias'
                                        value={competencia}
                                        onChange={handleCompetencia}
                                        options={competencias}
                                        isMulti
                                        maxValue={5}
                                    />
                                </div>
                                <div className='idioma-busquedas'>
                                    <label> Dominio de idioma medio-avanzado </label>
                                    <div></div>
                                    <label className='ejemplo-area'><span className='rojo'> Selecciona todas las opciones que correspondan </span></label>
                                    <div></div>
                                    <Select
                                        className='input-idiomas'
                                        value={idioma}
                                        onChange={handleIdioma}
                                        options={idiomas}
                                        isMulti
                                    />
                                </div>
                            </form>
                        )}
                    />
                    <progress id="barra" max="100" value="100"> 100% </progress>
                    <label className='porcentaje'>100%</label>
                    <button id='siguiente-busquedas' onClick={handleSubmit}>
                        {isLoading ? <CircularProgress size={24} /> : 'Enviar!'}
                    </button>
                    <button id='atras-busquedas' onClick={busqueda3}> Atras </button>
                </div>
                <div className='rectangulo-busquedas'></div>
                <img src={Circulo} alt='circulo' className='wot-circulo' />
            </div>
        </div>
    )
}

export default Busqueda4;