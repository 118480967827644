import React, { useState} from 'react';
import { Images } from './Images';
import { NormalInput } from './NormalInput';
import { HiddenInput } from './HiddenInput';
import { SubmitButton } from './SubmitButton';
import './Auth.css';
import Cookies from 'js-cookie';
import { useNavigate} from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from "@mui/material";
import { MUItheme } from './MUItheme';

export function Login() {

    const [formData, setFormData] = useState({
        mail: '',
        contraseña: '',
      });

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const navigate = useNavigate();
    
    const handleLogin = async (event) => {
        event.preventDefault();
    
        try {
            toast.success('Iniciando sesión...', {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
    
            let response = await axios.post(
                `https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/auth/login-company`, 
                {
                    email: formData.mail, 
                    password: formData.contraseña
                }
            );
    
    
            if (response.status === 201) {
                localStorage.setItem('accessToken', response.data.token);
                localStorage.setItem('refreshToken', response.data.refreshToken);
                localStorage.setItem('user', JSON.stringify(response.data.user)); 
                
                Cookies.set('jwtToken', response.data.access_token, { expires: 1 });
                Cookies.set('userId', response.data.id);
                navigate('/home');
            }
    
        } catch (error) {
            console.error(error);
    
            toast.error('Los datos ingresados son incorrectos', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };
    

    return (
    <>
      <ToastContainer limit={1}/>
    
      <div className='main-container'>
          <Images />
          <div className='rigth-container-login'>
            <ThemeProvider theme={MUItheme}>
                <h3 className='message-login'>Ingreso</h3>
                <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap' rel='stylesheet'></link>
                <NormalInput text='Usuario' value={'mail'} onInputChange={handleInputChange}/>
                <HiddenInput text='Contraseña' onInputChange={handleInputChange} type={'contraseña'}/>
                <div className='box-user-login'>
                    <a className='button-forgot-password' href="/forgotpassword">¿Olvidaste tu contraseña?</a>
                </div>
                <SubmitButton handleLogin={handleLogin} buttonText="Entrar" />
            </ThemeProvider>
          </div>
      </div>
    </>
)
}

export default Login;