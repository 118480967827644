import React from 'react';
import './Busqueda.css'
import people from '../../img/People.png';
import Circulo from '../../img/Circulo.png';


const Busqueda6 = () => {
    
    return (
        <div className="WT1">
            <div className='container-busquedas'>
                <label className='texto1'>¡Formulario enviado!</label> 
                <div></div>
                <img src={people} className="WT-logo2" alt="primera foto"/>
                <div></div>
                <label className='texto5'>El formulario ha sido enviado. Te contactaremos cuando tengamos candidatos que cumplan con lo requerido. ¡Bienvenida a la comunidad WOT!</label>
                <div></div>
            </div>
            <div className='rectangulo-busquedas'></div>
            <img src={Circulo} alt='circulo' className='wot-circulo' />
        </div>
    )   
}
export default Busqueda6;