import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import './Busqueda.css';
import Select from "react-select";
import { useNavigate } from 'react-router-dom';
import Circulo from '../../img/Circulo.png';

const Busqueda2 = () => {
    const [areas, setAreas] = useState([]);
    const [industrias, setIndustrias] = useState([]);
    const [profesiones, setProfesiones] = useState([]);
   
    const [areasBuscadas, setArea] = useState(JSON.parse(localStorage.getItem("areasBuscadas") || '[]'));
    const [sectorBuscado, setSector] = useState(JSON.parse(localStorage.getItem("sectorBuscado") || '[]'));
    const [profesionBuscada, setProfesion] = useState(JSON.parse(localStorage.getItem("profesionBuscada") || '[]'));

    const navigate = useNavigate();

    useEffect(() => {
        // Fetch areas
        fetch(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/obtenerListas/areas`)
        .then(response => response.json())
        .then(data => {
            const areas = data.map((index) => {
                return {value: index.id , label:index.nombre}
            })
            setAreas(areas);
        });
        // Fetch industrias
        fetch(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/obtenerListas/industrias`)
        .then(response => response.json())
        .then(data => {
            const industrias = data.map((index) => {
                return {value: index.id , label:index.nombre_industria}
            })
            setIndustrias(industrias);
        });
        // Fetch profesiones
        fetch(`https://gqim2rhigi.execute-api.us-east-1.amazonaws.com/prod-wot-backend/obtenerListas/profesiones`)
        .then(response => response.json())
        .then(data => {
            const profesiones = data.map((index) => {
                return {value: index.id , label:index.nombre}
            })
            setProfesiones(profesiones);
        });
    }, []);

    const handleLimitSelection = (selectedOptions, setState, limit) => {
        if (selectedOptions.length <= limit) {
            setState(selectedOptions);
            localStorage.setItem(setState === setArea ? "areasBuscadas" : setState === setSector ? "sectorBuscado" : "profesionBuscada", JSON.stringify(selectedOptions));
        } else {
            alert(`No puedes seleccionar más de ${limit} opciones.`);
        }
    };

    const handleAreasExperiencia = (selectedOptions) => {   
        handleLimitSelection(selectedOptions, setArea, 3);
    };
    const handleSectores = (selectedOptions) => {   
        handleLimitSelection(selectedOptions, setSector, 3);
    };
    const handleProfesiones = (selectedOptions) => {  
        handleLimitSelection(selectedOptions, setProfesion, 3);
    };
   
    function busqueda1() {
        navigate('/form-busqueda');
    }
    const handleSubmit = async(e) =>{
        if (profesionBuscada.length === 0 || areasBuscadas.length === 0|| sectorBuscado.length === 0) {
            alert("Por favor, completa todos los campos obligatorios.");
            return;
        } else {
            navigate('/form-busqueda3');
        } 
    }
    return (
    <div className="WT1">
        <label className='numero1-busquedas'>1</label>
        <label className='numero2-busquedas' id = 'active'>2</label>
        <label className='numero3-busquedas'>3</label>
        <label className='busqueda-busquedas'>Búsqueda</label>
        <label className='perfilProfesional-busquedas' id = 'negrita-busquedas'>Perfil profesional</label>
        <label className='requisitos-busquedas'>Requisitos</label>
        <div className='container-busquedas'>
            <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap' rel='stylesheet'></link>
            <h3 className='texto1-busquedas'>¡Estas haciendo tu formulario de búsqueda!</h3> 
            <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='profesion-busquedas'>
                            <label>¿Qué profesión o profesiones está buscando?<span className='rojo'>*</span></label>
                            <div></div>
                            <label className='ejemplo-profesion'><span className='rojo'> Seleccionar máximo 3 respuestas </span></label>
                            <div></div>
                            <Select
                                className='input-profesion'
                                value={profesionBuscada}
                                onChange={handleProfesiones}
                                options={profesiones}
                                isMulti
                                maxValue={3}
                            />
                        </div>
                        <div className='areas-busquedas'>
                            <label> Indique las áreas de experiencia que está buscando en la candidata <span className='rojo'>*</span></label>
                            <div></div>
                            <label className='ejemplo-area'><span className='rojo'> Seleccionar máximo 3 respuestas </span></label>
                            <div></div>
                            <Select
                                className='input-areas'
                                value={areasBuscadas}
                                onChange={handleAreasExperiencia}
                                options={areas}
                                isMulti
                                maxValue={3}
                            />
                        </div> 
                        <div className='industria-busquedas'>
                            <label> Indique los sector o industria de experiencia que está buscando en la candidata <span className='rojo'>*</span></label>
                            <div></div>
                            <label className='ejemplo-industria'> <span className='rojo'> Seleccionar máximo 3 respuestas </span> </label>
                            <div></div>
                            <Select
                                className='input-industria'
                                value={sectorBuscado}
                                onChange={handleSectores}
                                options={industrias}
                                isMulti
                                maxValue={3}
                            />
                        </div>
                    </form>   
                )}
            />
            <progress id="barra" max="100" value="50"> 50% </progress> 
            <label className='porcentaje'>50%</label>
            <button id='siguiente-busquedas' onClick={(e) => {handleSubmit()} }>
                Siguiente
            </button>
            <button id='atras-busquedas' onClick={(e) => {busqueda1()} }> Atras </button>
        </div>
        <div className='rectangulo-busquedas'></div>
        <img src={Circulo} alt='circulo' className='wot-circulo' />

    </div>
    )
}

export default Busqueda2;