import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export function SubmitButton({ handleLogin, buttonText }) {
  return (
    <Box className='box-user-login'>
      <Button onClick={handleLogin} variant="contained" style={{ fontFamily: 'Montserrat', width: '400px', height: '62px', textTransform: 'none', fontSize: '15px', backgroundColor: '#5A5A5A' }}>{buttonText}</Button>
    </Box>
  );
}

