import React from 'react';
import './Busqueda.css'
import { useNavigate } from 'react-router-dom';
import Circulo from '../../img/Circulo.png';
import { Form} from 'react-final-form';
import { useState } from "react";

const Busqueda5 = () => {
    const jwtToken = localStorage.getItem('accessToken');
    const user = JSON.parse(localStorage.getItem('user'));
    const companyId = user.data.attributes.empresa.id; 
    const[declaracion, setDeclaracion] = useState(true);
    const[terminos, setTerminos] = useState(true);
    const [flexible, setFlexible] = useState(false);
    const [necesidad, setNecesidad] = useState(false);
    const cargoBuscado = JSON.parse(localStorage.getItem("cargoBuscado"));
    const regionBuscada = JSON.parse(localStorage.getItem("regionBuscada"));
    const jornadaBuscada = JSON.parse(localStorage.getItem("jornadaBuscada"));
    const modalidadBuscada  = JSON.parse(localStorage.getItem("modalidadBuscada"));
    const areaBuscada = JSON.parse(localStorage.getItem("areaBuscada"));
    const horasRequeridas = JSON.parse(localStorage.getItem("horasRequeridas"));
    const trabajoFlexible = JSON.parse(localStorage.getItem("trabajoFlexible"));
    const necesidadViaje = JSON.parse(localStorage.getItem("necesidadViaje"));
    const profesionBuscada = JSON.parse(localStorage.getItem("profesionBuscada"));
    const anosBuscados = JSON.parse(localStorage.getItem("anosBuscados"));
    const areasBuscadas = JSON.parse(localStorage.getItem("areasBuscadas"));
    const sectorBuscado = JSON.parse(localStorage.getItem("sectorBuscado"));
    const altaDireccionBuscada = JSON.parse(localStorage.getItem("altaDireccionBuscada"));
    const experienciaDirectoriosBuscada = JSON.parse(localStorage.getItem("experienciaDirectoriosBuscada"));
    const competenciasBuscadas  = JSON.parse(localStorage.getItem("competenciasBuscadas"));
    const[altaDireccion, setAltaDireccion] = useState(false);
    const[experienciaDirectorios, setExperienciaDirectorios] = useState(false);
    const nombreBusqueda = JSON.parse(localStorage.getItem("nombreBusqueda"));
    //const formularioPersoBuscado = JSON.parse(localStorage.getItem("formularioPersoBuscado"));
    const idiomaBuscado = JSON.parse(localStorage.getItem("idiomaBuscado"));
    const postgradoBuscado = JSON.parse(localStorage.getItem("postgradoBuscado"));
    const [postgrado, setPostgrado] = useState(false);
    const uri = `http://localhost:3001/api/busqueda`
    //variables para guardar los valores del formulario
    const profesiones = [];
    const sectores = []; 
    const areas = []; 
    const competencias = []; 
    const idiomas = [];
    const handleDeclaracion= (e) => {
        setDeclaracion(e.target.value)
    };
    const handleTerminos= (e) => {
        setTerminos(e.target.value)
    };
    const navigate = useNavigate();
  
    function busqueda4() {
        navigate('/form-busqueda4');
    }
    const handleFetch = async (uri, infoBusquedaJSON) =>  { await fetch(uri,{
        method: "post", 
        headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-type":"application/json"
        },
        body: infoBusquedaJSON
    }).then(function(response) {
        }).catch((error)=>{
            console.error('Error:',error)
        })
    }
    const handleSubmit= async(e) =>{
        if (declaracion.trim() === "" || terminos.trim() === "") {
            alert("Por favor, completa todos los campos obligatorios.");
            return;
        } else{
            profesionBuscada.forEach((element) => {
                profesiones.push(element.value);
            });
            sectorBuscado.forEach((element) => {
                sectores.push(element.value);
            });
            competenciasBuscadas.forEach((element) => {
                competencias.push(element.value);
            });
            areasBuscadas.forEach((element) => {
                areas.push(element.value);
            });
            idiomaBuscado.forEach((element) => {
                idiomas.push(element.value);
            });
            if (trabajoFlexible === "true"){
                setFlexible(true);
            } else {
                setFlexible(false);
            }
            if (necesidadViaje === "true"){
                setNecesidad(true)
            } else{
                setNecesidad(false)
            }
            if (altaDireccionBuscada === "true"){
                setAltaDireccion(true)
            }else{
                setAltaDireccion(false)
            }
            if (experienciaDirectoriosBuscada === "true"){
                setExperienciaDirectorios(true)
            }else{
                setExperienciaDirectorios(false)
            }
            if (postgradoBuscado === "true"){
                setPostgrado(true)
            }else{
                setPostgrado(false)
            }
            localStorage.setItem("declaracion", JSON.stringify(declaracion));
            localStorage.setItem("terminos", JSON.stringify(terminos));
            const fecha = new Date();
            var infoBusqueda = {
                nombre: nombreBusqueda,
                fecha: fecha,
                id_empresa: companyId,//companyId, 
                id_cargo: parseInt(cargoBuscado), //ok
                id_jornada: parseInt(jornadaBuscada), //ok
                horas_requetidas: horasRequeridas, //ok
                id_modalidad: parseInt(modalidadBuscada), //ok
                area_especifica: true, // siempre es true
                area: parseInt(areaBuscada), //ok
                regionEspecifica: true, // siempre true
                id_region: parseInt(regionBuscada), //ok
                cargo_flexible: flexible, //ok 
                necesidad_viaje: necesidad,  //ok
                profesionEspecifica: true, //ok
                postgrado: postgradoBuscado, //ok
                id_anios_experiencia: parseInt(anosBuscados), //ok
                sectorIndustriaEspecifica: "Agricola", //no se usa 
                id_industria: 2, //no se usa
                experiencia_directorios: experienciaDirectorios, 
                alta_direccion: altaDireccion,
                profesiones: profesiones, //arreglar pq es una lista  se entrega el array    
                industrias: sectores, //arreglar pq es una lista
                areasExperiencia : areas, //arreglar pq es una lista
                competencias : competencias, //arreglar pq es una lista
                idiomas: idiomas, //arreglar pq es una lista       
                //agregar formulario personalidad
            }
            const infoBusquedaJSON = JSON.stringify(infoBusqueda);
            navigate('/form-busqueda6');
            await handleFetch( uri , infoBusquedaJSON);
        }
    }
    return (
    <div className="WT1">
        <label className='numero1-busquedas'>1</label>
        <label className='numero2-busquedas'>2</label>
        <label className='numero3-busquedas'>3</label>
        <label className='busqueda-busquedas'>Búsqueda</label>
        <label className='perfilProfesional-busquedas'>Perfil profesional</label>
        <label className='requisitos-busquedas'>Requisitos</label>
        <div className='container-busquedas'>
            <link href='https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap' rel='stylesheet'></link>
            <h3 className='texto1-busquedas'>Antes de enviar...</h3> 
            <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>
                        <div className='declaracion'>
                            <input type="checkbox" id="declaracion" name="declaracion" value={false} onChange={handleDeclaracion}/>
                            <label for="declaracion">Declaro que los datos ingresados son verídicos</label>
                        </div>
                        <div className='terminos-politicas'>
                        <a href='https://drive.google.com/file/d/1xgzD0NniJmMeO9NEfDdQAwfYpWTzbTQU/view?usp=share_link' className='terminos2'> Términos y Condiciones WoT</a>
                        <label>     </label>
                        <a href='https://drive.google.com/file/d/1Of7cSZ0yoRWYBOcTP08s9g5rIDGtN8TD/view?usp=share_link' className='politicas2'> Política de Privacidad WoT</a>
                        </div>
                        <div className='terminos'>
                            <input type="checkbox" name ="terminos" id="terminos" value={true} onChange={handleTerminos}/>
                            <label for="terminos">Acepto los términos y condiciones</label>
                        </div>
                    </form>    
                )}
            />
            <progress id="barra" max="100" value="50"> 75% </progress> 
            <label className='porcentaje'>75%</label>
            <button id='siguiente-busquedas' onClick={(e) => {handleSubmit()} }>
                Siguiente
            </button>
            <button id='atras-busquedas' onClick={(e) => {busqueda4()} }> Atras </button>
        </div>
        <div className='rectangulo-busquedas'></div>
        <img src={Circulo} alt='circulo' className='wot-circulo' />
    </div>
    )
}
export default Busqueda5;